$(document).ready(function () {
    var owl = $('.owl-carousel');
    var owl2 = $('.owl-carousel-offers');
    var owl3 = $('.owl-carousel-hotels');
    var owl4 = $('.owl-carousel-detail-pic');
    owl.owlCarousel({
        singleItem: true,
        autoPlay: false,
        pagination: true,
        navigation: false
    });
    owl2.owlCarousel({
        items: 3,
        lazyLoad: true,
        responsive: true,
        itemsDesktop: [1169, 3],
        itemsDesktopSmall: [969, 2],
        itemsTablet: [619, 1],
        itemsTabletSmall: false,
        itemsMobile: false
    });
    owl3.owlCarousel({
        items: 6,
        lazyLoad: true,
        responsive: true,
        itemsDesktop: [1169, 6],
        itemsDesktopSmall: [969, 4],
        itemsTablet: [619, 3],
        itemsTabletSmall: false,
        itemsMobile: false
    });
    owl4.owlCarousel({
        singleItem: true,
        lazyLoad: true,
        pagination: true
    });

    $('.main-carousel-inner .next').click(function () {
        owl.trigger('owl.next');
    });
    $('.main-carousel-inner .prev').click(function () {
        owl.trigger('owl.prev');
    });
    $('.main-content-offers .next').click(function () {
        owl2.trigger('owl.next');
    });
    $('.main-content-offers .prev').click(function () {
        owl2.trigger('owl.prev');
    });
    $('.sub-page-guide-detail .next').click(function () {
        owl4.trigger('owl.next');
    });
    $('.sub-page-guide-detail .prev').click(function () {
        owl4.trigger('owl.prev');
    });
    //$(".box-head.active").trigger("click");
    $('.box-head').click(function () {
        var arrow = $(this).parent().find(".box-separator").children('.icon-bg-arrow');
        var separator = $(this).parent().find(".box-content-separator");
        var content = $(this).parent().find(".box-content");
        $(".box-content").not(content).slideUp();
        $(".box-content-separator").not(separator).removeClass("active");
        $('.box .icon-bg-arrow').not(arrow).hide();
        $('.box-head').attr('data-content', 'bar');
        setTimeout(function () {
            arrow.toggle();
            separator.toggleClass("active");
            content.slideToggle("slow");
        }, 500);
    });

    var $wysiwyg = $(".sub-page-content");
    $wysiwyg.find("table, img").each(function() {
        $(this).wrap("<div class='elm_table'></div>");
    });

    $(document).on('click', '.footer-logo', function (e) {
        e.preventDefault();
        $('#copyrightspopup').fadeToggle('slow');
    });
    $(function () {
        var $map = $('.google__map');

        if (!$map.length) {
            return;
        }

        var coords = $map.data('coords').split(/[\s,]+/);
        var coords_main = $map.data('coords-main').split(/[\s,]+/);

        var coords_bounds = new google.maps.LatLng(coords[0], coords[1]),
                coords_bounds_main = new google.maps.LatLng(coords_main[0], coords_main[1]);

        var mapOptions = {
            zoom: 16,
            scrollwheel: false,
            draggable: true,
            mapTypeControl: false,
            center: {
                lat: parseFloat(coords[0]),
                lng: parseFloat(coords[1])
            }
        };

        var map = new google.maps.Map($map[0], mapOptions);

        var $bounds = new google.maps.LatLngBounds();

        var marker = new google.maps.Marker({
            position: {
                lat: parseFloat(coords[0]),
                lng: parseFloat(coords[1])
            },
            map: map,
            title: $map.data('title'),
            icon: {
                url: $map.data('marker'),
                scaledSize: new google.maps.Size(32, 49),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(0, 32)
            }
        });

        var marker_main = new google.maps.Marker({
            position: {
                lat: parseFloat(coords_main[0]),
                lng: parseFloat(coords_main[1])
            },
            map: map,
            title: $map.data('title-main'),
            icon: {
                url: $map.data('marker-main'),
                scaledSize: new google.maps.Size(32, 49),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(0, 32)
            }
        });

        google.maps.event.addListener(marker, 'click', function () {
            window.open($map.data("url"), '_blank');
        });

        $bounds.extend(coords_bounds);
        $bounds.extend(coords_bounds_main);

        map.fitBounds($bounds);

        google.maps.event.addListener(map, 'click', function () {
            map.setOptions({
                scrollwheel: true,
                draggable: !isTouch()
            });
        });
    });

    function fn_comp_visual() {
        var $fn = $(".comp_visual"),
            $slider = $fn.find("[data-visual-slider]"),
            $slider_caption = $fn.find("[data-visual-caption]"),
            $data_slider_autoplay = $slider.data("visual-slider");

        if ($slider.children().length > 1) {
            $slider.slick({
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                autoplay: true,
                autoplaySpeed: $data_slider_autoplay,
                arrows: true,
                nextArrow: "<span class=\"icon icon-chevron-right slick-next\"></span>",
                prevArrow: "<span class=\"icon icon-chevron-left slick-prev\"></span>",
                pauseOnHover: false,
                asNavFor: $slider_caption,
                dots: false,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            dots: true,
                        }
                    }
                ]
            });
            $slider.on('afterChange', function(){
                $(this).find(".slick-active").find("img").addClass("lazyload");
            });
        }
        if ($slider_caption.children().length > 1) {
            $slider_caption.slick({
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                arrows: false,
                dots: false,
                asNavFor: $slider,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 480,
                        settings: {
                            adaptiveHeight: false
                        }
                    }
                ]
            });
        }
    }
    fn_comp_visual();

    var picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") == -1 && $(window).width() < 1023)
    {
        $.getScript("https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js");
    }
});
